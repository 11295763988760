export const getDirector = (
  // TODO: 引数は使用していないため、削除予定
  id: number
): {
  name: string
  roma: string
  iconImagePath: string
  textLinkPath: string
} => {
  return {
    name: 'ライフドット編集部',
    roma: 'lifedot',
    iconImagePath:
      'https://s3.lifedot.jp/uploads/ending/path/60539/icon_Lifedot_150_150.png',
    textLinkPath: '/supervision-person/'
  }
}
